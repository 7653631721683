/* .user-card {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  aspect-ratio: 1/1.5;
  max-width: 200px;
  justify-content: center;
} */

.wrapper {
  border-radius: 10px;
  border: 4px solid darkgray;
  margin: 5px;
  background-color: white;
  padding-bottom: 5px;
}

.title-name {
  font-family: "Courier New", Courier, monospace;
  font-size: x-large;
  font-weight: bolder;
  margin: -1px;
  background-color: darkgray;
  margin-bottom: 10px;
}

.info {
  border-bottom: 2px solid lightgray;
  margin: 0 10px;
}

.centered {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
